body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

.leaflet-container {
  background-color: #f6f6fc;
}

.leaflet-pane .leaflet-tooltip-pane .leaflet-tooltip {
  box-shadow: 0px 0px 10px rgba(20, 22, 28, 0.08);
  border-radius: 8px;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 1px solid #f1f5f9;
  box-shadow: 0px 0px 10px rgba(20, 22, 28, 0.08);
}

.leaflet-bar a {
  color: #64748b;
  border-bottom: 1px solid #e2e8f0;
  transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.leaflet-bar a:not(.leaflet-disabled):hover {
  background-color: #f8fafc;
  color: #64748b;
}

.leaflet-bar a:hover:first-child {
  border-bottom: 1px solid #e2e8f0;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment {
  right: 12px;
}

.Toastify__toast--warning {
  background-color: #ff9100;
}

.container {
  display: flex;
  flex: 1;
}

*:focus-visible {
  outline: none;
}
